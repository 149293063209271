import { shopInfo, areaName, hvnUriRoot,slideArr } from "./core/defines";

const enterbanner = slideArr[0].img.imgPathSp
// console.log(enterbanner)

const hvnEnter = "https://www.cityheaven.net/hyogo/A2804/A280401/heaven_himegi/?of=y2"

const navs = [];
function Enterance(){
    return(
        <article id="enterence" className="txt-center">
            <header>{ areaName }のソープランド 【{ shopInfo.name }】</header>
            <picture>
                <source media="(max-width: 720px)" srcSet={enterbanner} />
                <source media="(min-width: 721px)" srcSet={enterbanner} />
                <img src={enterbanner} alt={ `${ areaName }のソープランド 【${ shopInfo.name }】` } />
            </picture>
            <div className="group-logo-wrap -mt">
                <a href="https://marineblue-g.com/" target="_blank">
                    <img src="/img/marin-group-logo.svg" alt="マリングループ ロゴ" width="250" height="50"/>
                </a>
            </div>
            
            <div className="wrappar">
                <p className="w-100 kin-txt">
                    <img src="/img/18txt.png" width="61" alt="当サイトはアダルトコンテンツを含みます。18歳未満の方のご利用は固く禁じられています。" />
                    当サイトはアダルトコンテンツを含みます。<br/>
                    18歳未満の方のご利用は固く禁じられています。
                </p>
                <a className="exit" href="https://www.yahoo.co.jp" nofollow>18歳未満の方は<u>コチラ</u>からご退出ください。</a>
                <div className="attention-container">
                    <div className="attention-box">
                        当店は暴力団を含む反社会的団体<br/>
                        及びスカウト等との関わりは一切ございません。
                    </div>
                    <div class="attention-wrap">
                        <div class="title">■加盟協会・団体</div>
                        <ul className="group-box">
                            <li>・全日本特殊浴場協会連合会</li>
                            <li>・一般社団法人東京特殊浴場暴力団等排除推進協議会</li>
                            <li>・一般社団法人全国防犯健全協力会</li>
                            <li>・東京防犯健全協力会</li>
                        </ul>
                    </div>
                </div>
                <nav className="enter">
                    <ul>
                        <li><a className="btn to-home" href="/home"><span>ENTER</span>18歳以上のお客様ページ</a></li>
                        <li><a className="btn to-hvn" href={ hvnEnter } target="_blank">ヘブン版ページはコチラ</a></li>
                    </ul>
                </nav>

                <section className="recruit">
                    <ul className="box">
                        <li><a className="btn women" href="http://heaven-himegi.hyogo-so.com/top/kyujin" target="_blank"><span>女性求人</span>ENTER</a></li>
                        <li><a className="btn men"   href="https://mensheaven.jp/8/heaven_himegi/?of=y" target="_blank"><span>男性求人</span>ENTER</a></li>
                    </ul>
                </section>

                {/* ヘブンバナー類 直書きのstyleの記述方が違うので注意。特にプロパティ名がlowCamelCaseの点に注意 */}
                <div class="enterbanner">
                    <div class="bnr-large">
                        <a href="https://marineblue-g.com/" target="_blank" rel="norefarrer noopner">
                            <img src="https://marineblue-g.com/img/bnr-group_20240701.jpg" width="468px" alt="マリングループ" />
                        </a>
                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "10px"}}>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <div><a style={{display: "block"}} href="https://www.cityheaven.net/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/heaven_logo.jpg" alt="ヘブンネット" width="236" height="68" border="0" /></a></div>
                            <div>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <a style={{display: "block"}} href="https://www.cityheaven.net/osaka/shop-list/biz6/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/01osaka.jpg" alt="大阪デリヘル" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://www.cityheaven.net/hyogo/shop-list/biz6/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/02hyogo.jpg" alt="兵庫デリヘル" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://www.cityheaven.net/kyoto/shop-list/biz6/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/03kyoto.jpg" alt="京都デリヘル" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://www.cityheaven.net/shiga/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/04shiga.jpg" alt="滋賀風俗" width="58" height="34" border="0" /></a>
                                </div>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <a style={{display: "block"}} href="https://www.cityheaven.net/hyogo/A2801/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/05fukuhara.jpg" alt="福原ソープ" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://www.cityheaven.net/hyogo/A2804/A280401/shop-list/biz6/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/06himeji.jpg" alt="姫路デリヘル" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://www.cityheaven.net/hyogo/A2802/shop-list/biz6/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/07kobe.jpg" alt="神戸デリヘル" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://www.cityheaven.net/hyogo/A2802/A280201/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.cityheaven.net/img/mutual_link/08sannomiya.jpg" alt="三宮風俗" width="58" height="34" border="0" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "10px"}}>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <div><a style={{display: "block"}} href="https://www.girlsheaven-job.net/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/girls.jpg" alt="ガールズヘブン" width="236" height="68" border="0" /></a></div>
                            <div>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <a style={{display: "block"}} href="https://www.girlsheaven-job.net/hyogo/ma-45/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/01kobe.jpg" alt="神戸風俗求人" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://www.girlsheaven-job.net/hyogo/ma-47/sa-463/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/02himeji.jpg" alt="姫路風俗求人" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://www.girlsheaven-job.net/hyogo/ma-45/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/03sannomiya.jpg" alt="三宮風俗求人" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://www.girlsheaven-job.net/hyogo/ma-45/sa-461/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/04fukuhara.jpg" alt="福原風俗求人" width="58" height="34" border="0" /></a>
                                </div>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <a style={{display: "block"}} href="https://www.girlsheaven-job.net/osaka/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/05osaka.jpg" alt="大阪風俗求人" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://www.girlsheaven-job.net/kyoto/shop-list/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/06kyoto.jpg" alt="京都風俗求人" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://www.girlsheaven-job.net/shiga/shop-list/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/07shiga.jpg" alt="滋賀風俗求人" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://www.girlsheaven-job.net/hyogo/shop-list/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.girlsheaven-job.net/img/fppc/08hyogo.jpg" alt="兵庫風俗求人" width="58" height="34" border="0" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "10px"}}>
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                            <div><a style={{display: "block"}} href="https://mensheaven.jp/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/job.jpg" alt="ジョブヘブン" width="236" height="68" border="0" /></a></div>
                            <div>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <a style={{display: "block"}} href="https://mensheaven.jp/8/hyogo/ma-47/shop-list/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/01himeji.jpg" alt="姫路男性高収入" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://mensheaven.jp/8/hyogo/ma-45/shop-list/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/02kobe.jpg" alt="神戸男性高収入" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://mensheaven.jp/8/hyogo/ma-45/sa-460/shop-list/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/03sannomiya.jpg" alt="三宮男性高収入" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://mensheaven.jp/8/hyogo/ma-45/sa-461/shop-list/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/04fukuhara.jpg" alt="福原男性高収入" width="58" height="34" border="0" /></a>
                                </div>
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <a style={{display: "block"}} href="https://mensheaven.jp/8/osaka/shop-list/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/05osaka.jpg" alt="大阪男性高収入" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://mensheaven.jp/8/kyoto/shop-list/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/06kyoto.jpg" alt="京都男性高収入" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://mensheaven.jp/8/shiga/shop-list/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/07shiga.jpg" alt="滋賀男性高収入" width="58" height="34" border="0" /></a>
                                    <a style={{display: "block"}} href="https://mensheaven.jp/8/hyogo/shop-list/" target="_blank" rel="noopener"><img style={{display: "block"}} loading="lazy" src="https://img.mensheaven.jp/img/fppc/bnr/08hyogo.jpg" alt="兵庫男性高収入" width="58" height="34" border="0" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div><p style={{textAlign: "center", paddingBottom: "3px", margin: 0}}><a href="https://www.cityheaven.net/hyogo/A2804/A280401/shop-list/biz4/" target="_blank"><img src="https://img.cityheaven.net/img/mutual_link/468_68_322.jpg" width="468" height="68" border="0" alt="姫路のソープ｜シティヘブンネット" /></a></p></div>
                    <div><p style={{textAlign: "center", paddingBottom: "3px", margin: 0}}><a href="https://www.girlsheaven-job.net/hyogo/ma-47/sa-463/" target="_blank"><img src="https://img.girlsheaven-job.net/img/fppc/468_68_322.png" width="468" height="68" border="0" alt="姫路の風俗求人｜ガールズヘブン" /></a></p></div>
                    <div><p style={{textAlign: "center", paddingBottom: "3px", margin: 0}}><a href="https://mensheaven.jp/8/hyogo/shop-list/" target="_blank"><img src="https://img.mensheaven.jp/img/fppc/bnr/058.jpg" width="468" height="68" border="0" alt="兵庫のスタッフ求人｜ジョブヘブン" /></a></p></div>
                    <div>
                        <a href="https://yoasobi-heaven.com/en/hyogo/" target="_blank">
                            <img src="https://yoasobi-heaven.com/img/renewal/linklist/bn_yoasobi-heaven_468x68.jpg" width="468" height="68" border="0" alt="YOASOBIHEAVEN"/>
                        </a>
                    </div>
                </div>
            </div>
        </article>
    );
}

export default Enterance;